export const electoralVotesMap = {
    "Alabama":9,
    "Alaska":3,
    "Arizona":11,
    "Arkansas":6,
    "California":54,
    "Colorado":10,
    "Connecticut":7,
    "Delaware":3,
    "District of Columbia":3,
    "Florida":30,
    "Georgia":16,
    "Hawaii":4,
    "Idaho":4,
    "Illinois":19,
    "Indiana":11,
    "Iowa":6,
    "Kansas":6,
    "Kentucky":8,
    "Louisiana":8,
    "Maine":2,
    "Maine1":1,
    "Maine2":1,
    "Maryland":10,
    "Massachusetts":11,
    "Michigan":15,
    "Minnesota":10,
    "Mississippi":6,
    "Missouri":10, 
    "Montana":4,
    "Nebraska":2,
    "Nebraska1":1,
    "Nebraska2":1,
    "Nebraska3":1,
    "Nevada":6,
    "New Hampshire":4,
    "New Jersey":14,
    "New Mexico":5,
    "New York":28,
    "North Carolina":16,
    "North Dakota":3,
    "Ohio":17,
    "Oklahoma":7,
    "Oregon":8,
    "Pennsylvania":19,
    "Rhode Island":4,
    "South Carolina":9,
    "South Dakota":3,
    "Tennessee":11,
    "Texas":40, 
    "Utah":6,
    "Vermont":3,
    "Virginia":13,
    "Washington":12, 
    "West Virginia":4,
    "Wisconsin":10,
    "Wyoming":3
}